import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Section } from "../components/Container2"
import { GatsbyFluidImageProps } from "gatsby-source-sanity"
import { Maybe } from "purify-ts"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

type HeroProps = {
  fluidImage: Maybe<GatsbyFluidImageProps>
}

const Hero: React.FC<HeroProps> = ({ fluidImage }) => (
  <>
    {fluidImage.mapOrDefault(
      img => (
        <StyledBackgroundImageContainer fluid={img} />
      ),
      null
    )}
  </>
)

const StyledBackgroundImageContainer = styled(BackgroundImage)`
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 100vh;
  max-width: none;

  @media (max-width: 1000px) {
    margin-top: -30vh;
    height: 80vh;
  }
`

type InstagramImages = {}

type NextConcerts = {}

export type FrontPageElements = InstagramImages | NextConcerts | Section

type FrontPageQuery = {
  sanityFrontPage: {
    _rawContent: FrontPageElements[]
    headerImage?: {
      asset: {
        fluid: GatsbyFluidImageProps
      }
    }
  }
}

const IndexPage = ({ data }: { data: FrontPageQuery }) => {
  return (
    <Layout>
      <SEO title="DVK" />
      <Hero
        fluidImage={Maybe.fromNullable(
          data.sanityFrontPage.headerImage?.asset.fluid
        )}
      />
      <Container blocks={data.sanityFrontPage._rawContent} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query FrontPageQuery {
    sanityFrontPage {
      _rawContent
      headerImage {
        asset {
          fluid(maxWidth: 4000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
